body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.registry-table {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.bm-item-list a span {
  margin-left: 10px;
  font-weight: 700;
}

.bm-item-list a {
  padding: 0.8em;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  color: #b8b7ad;
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: lightgray;
  color: white;
  text-align: center;
}

.bg{
  background-position:center center;
  background-repeat:no-repeat;
  background-size:cover; /* you change this to "contain" if you don't want the images to be cropped */
  color:#fff;
}

.square {
  float: right;
  position: relative;
  width: 100%;
  padding-bottom : 100%; /* = width for a 1:1 aspect ratio */
  margin:0;
  background-color:lightgray;
  overflow:hidden;
}

.content {
  position:absolute;
  height:100%; /* = 100% - 2*5% padding */
  width:100%; /* = 100% - 2*5% padding */
  /* padding: 5%; */
  
}

.content .rs{
  width:auto;
  height:auto;
  max-height:100%;
  max-width:100%;
}